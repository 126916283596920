<template>
    <div class="inscription container-fluid mx-auto d-flex-center flex-column my-4 flex-fill body-container">
        <div v-if="isLoading" class="loader d-flex-center">
            <div class="d-flex-center flex-column bg-secondary bg-opacity-75 rounded p-3 pt-5">
                <i class="fa-solid fa-medal text-primary-xlight fa-beat fa-2xl"></i>
                <strong class="text-primary-xlight mt-4--5 fs-4">Envoi de la réponse..</strong>
            </div>
        </div>

        <div class="row g-3">
            <div class="col-12 col-lg-5 col-xl-6 d-flex-center">
                <img
                    src="../assets/img/affiche.png"
                    class="mw-100 shadow border border-5 border-primary-light rounded"
                    alt=""
                />
            </div>
            <div class="col-12 col-lg-7 col-xl-6 d-flex-center flex-column">
                <div class="border border-5 border-primary-light p-3 shadow rounded">
                    <div class="my-2 text-center"><img src="../assets/img/oj.png" alt="" /></div>

                    <h1 class="w-100 text-secondary rounded mb-3 py-3">Formulaire de réponse</h1>

                    <form method="POST" class="inscription__form needs-validation" novalidate>
                        <div class="row g-3 justify-content-around">
                            <div class="col-12 d-flex-center py-3">
                                <span class="fs-5">Je souhaite participer à l'événement :</span>
                                <button
                                    type="button"
                                    class="btn btn-lg mx-3"
                                    v-bind:class="[form_data.is_participating ? 'btn-primary' : 'btn-outline-primary']"
                                    @click="participate(true)"
                                    for="chk_yes"
                                >
                                    Oui
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-lg"
                                    v-bind:class="[
                                        form_data.is_participating ? 'btn-outline-secondary' : 'btn-secondary',
                                    ]"
                                    @click="participate(false)"
                                    for="chk_no"
                                >
                                    Non
                                </button>
                            </div>
                            <div class="col-12 col-sm-6">
                                <div class="form-floating">
                                    <input
                                        class="form-control text-uppercase"
                                        type="text"
                                        v-model="form_data.last_name"
                                        id="last_name"
                                        name="last_name"
                                        placeholder="Dupond"
                                        required
                                    />
                                    <label for="last_name">Nom*</label>
                                    <div class="invalid-feedback">Veuillez renseigner votre nom</div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6">
                                <div class="form-floating">
                                    <input
                                        class="form-control text-capitalize"
                                        type="text"
                                        v-model="form_data.first_name"
                                        id="first_name"
                                        name="first_name"
                                        placeholder="Alain"
                                        required
                                    />
                                    <label for="first_name">Prénom*</label>
                                    <div class="invalid-feedback">Veuillez renseigner votre prénom</div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-7">
                                <div class="form-floating">
                                    <input
                                        class="form-control"
                                        type="email"
                                        v-model="form_data.email"
                                        id="email_address"
                                        name="email_address"
                                        placeholder="nom@exemple.fr"
                                        required
                                    />
                                    <label for="email_address">Adresse email*</label>
                                    <div class="invalid-feedback">Adresse email invalide</div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-5">
                                <div class="form-floating">
                                    <input
                                        class="form-control"
                                        type="tel"
                                        v-model="form_data.phone_number"
                                        id="phone_number"
                                        name="phone_number"
                                        placeholder="0701020304"
                                    />
                                    <label for="phone_number">Numéro de téléphone</label>
                                </div>
                            </div>
                        </div>

                        <button @click="register" class="w-100 btn btn-primary btn-lg mt-3" type="button">
                            Valider
                        </button>
                        <div class="mt-2 small">* champs obligatoires</div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from "../plugins/router";
import { functions } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";
const OlivierJacquand_SaveRegistration = httpsCallable(functions, "OlivierJacquand_SaveRegistration");

const empty_form = {
    is_participating: true,
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
};

export default {
    created() {
        this.cleanData();
    },
    data() {
        return {
            isLoading: false,
            hasError: false,
            form_data: {
                is_participating: true,
                last_name: "Modin",
                first_name: "Damien",
                email: "damien+sb@innov-events.fr",
                phone_number: "0789215568",
            },
        };
    },
    props: {},
    methods: {
        participate(is_participating) {
            this.form_data.is_participating = is_participating;
        },
        async register() {
            if (!this.isLoading) {
                this.isLoading = true;
                const form = document.querySelector(".needs-validation");
                form.classList.add("was-validated");

                if (form.checkValidity()) {
                    await OlivierJacquand_SaveRegistration(this.form_data)
                        .then((result) => {
                            const data = result.data;
                            if (data) {
                                localStorage.p = this.form_data.is_participating;
                                this.cleanData();
                                router.push({ name: "done" });
                            } else {
                                this.$data.hasError = true;
                                form.classList.remove("was-validated");
                                window.scrollTo(0, 0);
                            }
                        })
                        .catch((error) => {
                            const code = error.code;
                            const message = error.message;
                            const details = error.details;
                            console.log(code, message, details);
                            this.$data.hasError = true;
                            form.classList.remove("was-validated");
                            window.scrollTo(0, 0);
                        });
                }

                this.isLoading = false;
            }
        },
        cleanData() {
            this.form_data = Object.assign({}, empty_form);
            const form = document.querySelector(".needs-validation");
            form?.classList.remove("was-validated");
        },
    },
};
</script>

<style lang="scss" scoped>
$primary: #6c472a;
$secondary: #d0a260;
.loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba($color: $primary, $alpha: 0.3);
}
</style>
